import { render, staticRenderFns } from "./check_in_travelers_details.vue?vue&type=template&id=1801d1b3&scoped=true&"
import script from "./check_in_travelers_details.vue?vue&type=script&lang=js&"
export * from "./check_in_travelers_details.vue?vue&type=script&lang=js&"
import style0 from "./check_in_travelers_details.vue?vue&type=style&index=0&id=1801d1b3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1801d1b3",
  null
  
)

export default component.exports